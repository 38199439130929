import React, { useEffect, useRef, useState } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";
import { trackEvent, trackNonInteractionEvent } from "@leafly-com/web-utils";
import classnames from "classnames";
import { useSelector } from "react-redux";

import getBrandPlacement, { Placement } from "api/requests/getBrandPlacement";
import { Dispensary } from "custom-types/Dispensary";
import { MenuItem } from "custom-types/MenuItem";
import { getUserPrefersMedical } from "redux/selectors/user";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import { MenuType } from "utils/menuTypeUtils";
import { getSplitKey } from "utils/split/getSplitKey";

import Carousel from "components/botanic/Carousel";
import MenuCard from "components/Dispensary/MenuCard";
import TrackImpression from "components/TrackImpression";

type CarouselProps = {
  brand: Placement["brand"];
  cardsPerSlide?: number;
  menuItems: Placement["menuItems"];
  subtitle?: string;
  title?: string | JSX.Element;
};

const PromotedBrandCarousel: React.FC<{
  dispensary: Dispensary;
  brandPlacement: Placement | null;
  cardsPerSlide?: number;
  className?: string;
}> = ({ brandPlacement, cardsPerSlide = 4, className, dispensary }) => {
  const isDualLicenseDispensary = isDispensaryDualLicense(dispensary.tags);
  const userPrefersMedical = useSelector(getUserPrefersMedical);

  const [placement, setPlacement] = useState<Placement | null>(brandPlacement);

  const menuType = isDualLicenseDispensary
    ? userPrefersMedical
      ? MenuType.Med
      : MenuType.Rec
    : undefined;

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      getBrandPlacement(dispensary.slug, {
        menuType,
        strategies: ["lite-amplification", "menu-merchandising"],
      }).then((placement) => {
        setPlacement(placement);
      });
    }

    isMounted.current = true;
  }, [menuType]);

  /**
   * For now, we'll wait to display anything until there is confirmation
   * that there is a brand to promote. This will cause a reflow that
   * potentially incurs a Cumulative Layout Shift penalty for pages that
   * include this component. This decision should be reassessed at the
   * point where there are enough brands that the hit/miss ratio for showing
   * a promoted brand leans more heavily towards hit.
   */
  if (!placement) {
    return null;
  }

  const { brand, menuItems } = placement;

  const title = `Shop ${brand.name} at ${dispensary.name}`;
  const subtitle = `Sponsored by ${brand.name}`;

  return (
    <div className={classnames(className)}>
      {brand.promotedVia === "menu-merchandising" ? (
        <MenuMerchandisingCarousel
          brand={brand}
          cardsPerSlide={cardsPerSlide}
          dispensary={dispensary}
          menuItems={menuItems}
          subtitle={subtitle}
          title={<h2 className="heading--m font-extrabold">{title}</h2>}
        />
      ) : (
        <LiteAmplificationCarousel
          brand={brand}
          cardsPerSlide={cardsPerSlide}
          menuItems={menuItems}
          subtitle={subtitle}
          title={<h2 className="heading--m font-extrabold">{title}</h2>}
        />
      )}
    </div>
  );
};

const LiteAmplificationCarousel: React.FC<CarouselProps> = ({
  brand,
  cardsPerSlide,
  menuItems,
  subtitle,
  title,
}) => {
  const trackingLabel = `Sponsored Products Carousel - ${brand.name}`;

  const onClick = () =>
    trackEvent("Dispensary Menu", "Click", trackingLabel, {
      brandId: brand.id,
    });

  return (
    <TrackImpression
      category="Dispensary Menu"
      action="impression"
      customDimensions={{ brandId: brand.id }}
      label={trackingLabel}
    >
      <Carousel cardsPerSlide={cardsPerSlide} subtitle={subtitle} title={title}>
        {menuItems.map((menuItem: MenuItem) => (
          <MenuCard
            dispensaryId={menuItem.dispensary.id}
            key={menuItem.id}
            menuItem={menuItem}
            onClick={onClick}
          />
        ))}
      </Carousel>
    </TrackImpression>
  );
};

const CARD_TRACKING = {
  action: "impression",
  category: "Sponsored Menu Item Card",
  label: "Menu Merch Card",
};

const MenuMerchandisingCarousel: React.FC<
  CarouselProps & { dispensary: Dispensary }
> = ({ brand, cardsPerSlide, dispensary, menuItems, subtitle, title }) => {
  const carouselTracking = {
    category: "Sponsored Menu Merch Carousel",

    customDimensions: {
      brandId: brand.id,
      dispensaryId: dispensary.id,
    },
    /**
     * This label will be tracked by BI to determine how many cards show
     * up in Menu Merchandising carousels.
     */
    label: menuItems.length,
  };

  useEffect(() => {
    /**
     * Menu Merchandising is sold based on pageviews at the time of this
     * writing (2021/28/10). Given that the brands purchasing this addon
     * expect a certain lift based on pageviews rather than visible
     * impressions, we're tracking impressions on this carousel regardless
     * of whether the element is actually visible to the consumer.
     */
    trackNonInteractionEvent(
      carouselTracking.category,
      "Impression",
      carouselTracking.label,
      carouselTracking.customDimensions,
    );
  }, []);

  return (
    <Carousel cardsPerSlide={cardsPerSlide} title={title} subtitle={subtitle}>
      {menuItems.map((menuItem: MenuItem, i: number) => {
        const trackingDimensions = {
          ...carouselTracking.customDimensions,
          carouselSlot: i + 1,
          menuItemId: menuItem.menuItemId,
          merchandisingCampaignId: brand.campaignId,
          productId: menuItem.product?.id,
          strainId: menuItem.strain?.id,
        };

        return (
          <TrackImpression
            key={menuItem.id}
            customDimensions={trackingDimensions}
            className="h-full"
            {...CARD_TRACKING}
          >
            <MenuCard
              dispensaryId={menuItem.dispensary.id}
              menuItem={menuItem}
              campaignId={brand.campaignId}
              onClick={() => {
                trackEvent(
                  CARD_TRACKING.category,
                  "click",
                  CARD_TRACKING.label,
                  trackingDimensions,
                );
              }}
              onAddToBagClick={() => {
                trackEvent(
                  CARD_TRACKING.category,
                  "click",
                  "Menu Merch ATB Button",
                  trackingDimensions,
                );
                sendClientSideSplitTrackEvent(
                  "webDispensaryMenuItem_addToBagClick_frontend",
                  getSplitKey(),
                );
              }}
            />
          </TrackImpression>
        );
      })}
    </Carousel>
  );
};

export default PromotedBrandCarousel;
